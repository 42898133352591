<template>
    <vs-tabs v-model="tabs">
        <vs-tab label="Handover">
            <handover @edit="edit" />
        </vs-tab>
        <vs-tab label="Form">
            <div v-if="!allowForm"></div>
            <handover-form v-else :handover_id="handoverId" @close="close" />
        </vs-tab>
    </vs-tabs>
</template>
<script>
import Handover from "./Handover.vue"
import HandoverForm from "./Form.vue"
export default {
    components: {
        HandoverForm,
        Handover
    },
    data() {
        return {
            tabs: 0,
            handoverId: null,
            allowForm: false,
        }
    },
    methods: {
        edit(params) {
            this.allowForm = true
            this.tabs = 1
            this.handoverId = params.handover_id
        },
        close() {
            this.allowForm = false
            this.tabs = 0
        },
    }
}
</script>