<template>
  <vx-card title="Simple Outbound Processing">
    <div class="vx-input-group flex">
      <vs-tabs v-if="render" v-model="tab">
        <vs-tab label="New" :disabled="setting == 'Auto'">
          <tab-new></tab-new>
        </vs-tab>
        <vs-tab label="Process" :disabled="setting == 'Auto'">
          <tab-process></tab-process>
        </vs-tab>
        <vs-tab label="Handover">
          <tab-handover></tab-handover>
        </vs-tab>
        <vs-tab label="Pickup">
          <tab-pickup></tab-pickup>
        </vs-tab>
        <vs-tab label="Cancel" :disabled="setting == 'Auto'">
          <tab-cancel></tab-cancel>
        </vs-tab>
        <vs-tab label="Complete">
          <tab-complete></tab-complete>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>

<script>
// import TabNew from "./TabNew.vue";
import TabNew from "./new/index.vue";
// import TabProcess from "./TabProcess.vue";
import TabProcess from "./process/index.vue";
// import TabHandover from "./TabHandover.vue";
import TabHandover from "./handover/index.vue";
// import TabComplete from "./TabComplete.vue";
import TabCancel from "./TabCancel.vue";
import TabPickup from "./pickup/index.vue";
// import TabComplete from "./complete/index.vue";
import TabComplete from "./complete/index2.vue";

export default {
  components: {
    TabNew,
    TabProcess,
    TabHandover,
    TabComplete,
    TabCancel,
    TabPickup,
  },
  data() {
    return {
      activeTab: "New",
      render: true,
      setting: "",
      tab: 0,
    };
  },
  mounted() {
    this.$vs.loading();
    this.$http.get(`/api/wms/v1/simple-outbound-planner/setting`)
    .then((resp) => {
      if (resp.code == 200) {
        this.setting = resp.data;
        if (this.setting == "Auto") {
          this.tab = 2;
        }
        this.render = false;
        this.$nextTick(() => (this.render = true));
        this.$vs.loading.close();
      }
    })
    .catch(error => {
      console.log(error)
    });
  },
  methods: {},
};
</script>
