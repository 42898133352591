<template>
    <vx-card title="Pickup POD">
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row pt-2">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>SO Data</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input readonly :value="data.SalesOrder.Code" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>DO Data</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input readonly :value="data.Code" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>POD Number</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input readonly :value="data.ProofOfDelivery.Code" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>POD Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input readonly :value="data.ProofOfDelivery.Date" class="w-full"></vs-input>
                    </div>
                </div>

                <div class="mb-6 vx-row pt-2">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>POD Status</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect v-model="data.ProofOfDelivery.status" :options="status"
                            placeholder="Type to search" track-by="id" label="text" :max-height="125"
                            @select="changeStatus" :option-disabled="isOptionDisabled">
                            <template slot="option" slot-scope="{ option }">
                                <span
                                    :class="{ 'is-disabled': option.text == 'Partial Complete' && !isAllowPartial, }">{{
                                        option.text }}</span>
                            </template>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <!-- ... -->
                    <div class="mb-6 vx-row pt-2">
                        <div class="vx-col sm:w-1/3 w-full flex items-center">
                            <span>POD Posting Date</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                            <datepicker
                                v-model="selectedPostingDate"
                                format="yyyy-MM-dd"
                                input-class="input-class"
                                :disabled-dates="disabledDates"
                            ></datepicker>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br>
        <table class="table table-stripe" v-if="renderTable">
            <thead>
                <th>SKU Code</th>
                <th>SKU Name</th>
                <th>HU</th>
                <th>DO Qty</th>
                <th>Batch Number</th>
                <th>Batch External</th>
                <th>Expired Date</th>
                <th>Rejected Qty</th>
                <th>Accepted Qty</th>
                <th>Reason</th>
                <th>Note (optional)</th>
            </thead>
            <tbody>
                <tr :key="indextr" v-for="(tr, indextr) in data.DeliveryOrderLine">
                    <td>{{ tr.PackingLine.SkuCode }}</td>
                    <td>{{ tr.PackingLine.ItemName }}</td>
                    <td>{{ tr.PackingLine.ItemUnit }}</td>
                    <td>{{ tr.PackingLine.Amount }}</td>
                    <td>{{ tr.PackingLine.Batch }}</td>
                    <td>{{ tr.PackingLine.BatchExternal }}</td>
                    <td>{{ formatDate(tr.PackingLine.ExpiredDate) }}</td>
                    <td>
                        <vs-input :disabled="[1, 4].includes(data.ProofOfDelivery.status.id)"
                            v-model="tr.ProofOfDeliveryLine.ReturnQuantity"
                            @input="(e) => calculateQuantity(e, indextr)"></vs-input>
                    </td>
                    <td>{{ tr.ProofOfDeliveryLine.AdjustedQuantity }}</td>
                    <td>
                        <multiselect :disabled="data.ProofOfDelivery.status.id == 1"
                            v-model="tr.ProofOfDeliveryLine.reason" :options="reasons" placeholder="Type to search"
                            track-by="id" label="reason" :max-height="125" @select="logSelect">
                        </multiselect>
                    </td>
                    <td>
                        <vs-input :disabled="data.ProofOfDelivery.status.id == 1"
                            v-model="tr.ProofOfDeliveryLine.Note"></vs-input>
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" color="primary" @click="handleSave(false)"
                        :disabled="data.ProofOfDelivery.Status == 4">Save</vs-button>
                    <vs-button class="mb-2" color="success" @click="handleSave(true)"
                        :disabled="data.ProofOfDelivery.Status == 4">Save & Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        Datepicker,
    },
    props: {
        do_id: Number,
    },
    data() {
        return {
            selectedPostingDate: moment().toDate(),
            disabledDates: {
                to: new Date(new Date().setDate(new Date().getDate() - 1)) // Semua tanggal sebelum hari ini akan dinonaktifkan
            },
            status: [
                {
                    id: 0,
                    text: "Open",
                    $isDisabled: false,
                },
                {
                    id: 1,
                    text: "Complete",
                    $isDisabled: false,
                },
                {
                    id: 2,
                    text: "Partial Complete",
                    $isDisabled: false,
                },
                {
                    id: 4,
                    text: "Failed Cancel",
                    $isDisabled: false,
                },
            ],
            reasons: [],
            renderTable: true,
            table: this.tableDefaultState(),
            data: {
                SalesOrder: {},
                ProofOfDelivery: {},
            },
            pickingListNumber: null,
            checkedAll: false,
            checked: [],
            rdd: moment().format("D MMMM yyyy"),
            isAllowPartial: false,
        };
    },
    computed: {
        formatDate: () => {
            return (val) => (val ? moment.utc(val).format("YYYY-MM-DD") : "");
        },
    },
    methods: {
        isOptionDisabled(option) {
            return option.name == "Partial Complete" && !this.isAllowPartial;
        },
        calculateQuantity(event, idx) {
            const returnQuantity = parseInt(event || "0");
            const podLine = this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine;
            this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine.AdjustedQuantity = podLine.Quantity - returnQuantity;
        },
        logSelect(val) {
            this.renderTable = false;
            this.$nextTick(() => (this.renderTable = true));
        },
        tableDefaultState() {
            return {
                data: [
                    {
                        SKUCode: "89898989",
                        SKUName: "ULI JONGGOL",
                        HU: "uwowowow",
                        Quantity: "98246",
                        storageArea: "MW9NF9P",
                        batchNumber: "823748280374",
                        expDate: "24 JANUARI 2027",
                    },
                ],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        getReason(reason_group) {
            if (reason_group) {
                this.$http.get(`/api/wms/v1/master/reason`, {
                    params: { reason_group },
                })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.reasons = resp.data.records;
                            this.data.DeliveryOrderLine.forEach((dol, idx) => {
                                this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine.reason = this.findReason(dol.ProofOfDeliveryLine.ReasonID);
                            });
                        } else {
                            //
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            } else {
                this.reasons = [];
            }
        },
        findStatus(statusId) {
            return this.status.find((s) => s.id == statusId);
        },
        findReason(reasonId) {
            const res = this.reasons.find((r) => r.id == reasonId);
            return res;
        },
        getData() {
            this.$vs.loading();
            this.$http.get(`/api/wms/v1/simple-outbound-planner/do/${this.do_id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.data = resp.data;
                        console.log(resp.data)
                        this.data.ProofOfDelivery.status = this.findStatus(
                            this.data.ProofOfDelivery.StatusDelivery
                        );
                        this.changeStatus(this.data.ProofOfDelivery.status);
                        this.data.ProofOfDelivery.Date =
                            this.data.ProofOfDelivery.Date.split("T")[0];
                        this.data.DeliveryOrderLine.forEach((dol, idx) => {
                            this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine.reason = {
                                id: 0,
                            };
                        });

                        this.isAllowPartial =
                            this.data.SalesOrder.AllowPartial == 1 ? true : false;

                        if (!this.isAllowPartial) {
                            this.status.forEach((s) => {
                                if (s.id == 2) {
                                    s.$isDisabled = true;
                                }
                            });
                        }

                        this.selectedPostingDate = (this.data.ProofOfDelivery.PostingDate) ? moment(this.data.ProofOfDelivery.PostingDate).toDate() : moment().toDate();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        emitClose() {
            this.$emit("close");
        },
        handleSave(confirm) {
            if (confirm) {
                if (this.data.ProofOfDelivery.status.id == 0) {
                    this.$vs.dialog({
                        color: "warning",
                        title: "Warning",
                        text: "Please Update POD Status first",
                    });
                    return;
                }
            }

            if (this.data.ProofOfDelivery.status.id == 2) {
                var totalReturn = 0;
                this.data.DeliveryOrderLine.forEach((dol) => {
                    totalReturn += dol.ProofOfDeliveryLine.ReturnQuantity = parseInt(dol.ProofOfDeliveryLine.ReturnQuantity || "0");
                });
                if (totalReturn == 0) {
                    this.$vs.dialog({
                        color: "warning",
                        title: "Warning",
                        text: "Please input return quantity minimum 1 item",
                    });
                    return;
                }
            }

            if (this.data.ProofOfDelivery.status.id == 2) {
                var hasRejectedNoReason = 0;
                this.data.DeliveryOrderLine.forEach((dol) => {
                    if (dol.ProofOfDeliveryLine.ReturnQuantity != 0 && dol.ProofOfDeliveryLine.reason == undefined) {
                        hasRejectedNoReason++;
                    }
                });

                if (hasRejectedNoReason > 0) {
                    this.$vs.dialog({
                        color: "warning",
                        title: "Warning",
                        text: "Please choose reason for rejected item",
                    });
                    return;
                }
            }

            if (this.data.ProofOfDelivery.status.id == 4) {
                var noReasonTotal = 0;
                this.data.DeliveryOrderLine.forEach((dol) => {
                    if (!dol.ProofOfDeliveryLine.reason) {
                        noReasonTotal++;
                    }
                });

                if (noReasonTotal > 0) {
                    this.$vs.dialog({
                        color: "warning",
                        title: "Warning",
                        text: "Please choose reason for all rejected item",
                    });
                    return;
                }
            }

            const podLines = this.data.DeliveryOrderLine.map((dol) => ({
                id: dol.ProofOfDeliveryLine.id,
                quantity: dol.ProofOfDeliveryLine.Quantity,
                adjusted_quantity: dol.ProofOfDeliveryLine.AdjustedQuantity,
                return_quantity: parseInt(dol.ProofOfDeliveryLine.ReturnQuantity || "0"),
                reason_id: dol.ProofOfDeliveryLine.reason
                    ? dol.ProofOfDeliveryLine.reason.id
                    : null,
                note: dol.ProofOfDeliveryLine.Note,
            }));

            var emitClose = this.emitClose;
            var selectedPostingDate = this.selectedPostingDate;
            var data = this.data;
            var do_id = this.do_id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Pickup Save Confirmation`,
                text: "Please confirm to process this data",
                accept: function () {
                    this.$vs.loading();
                    this.$http.patch(`/api/wms/v1/simple-outbound-planner/pod/${do_id}`, {
                        status_delivery: data.ProofOfDelivery.status.id,
                        pod_lines: podLines,
                        confirm: confirm,
                        posting_date: moment(selectedPostingDate).format("YYYY-MM-DD"),
                    })
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully proceessed",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });

                                emitClose();
                            } else {
                                this.$vs.dialog({
                                    color: "danger",
                                    title: "Something went wrong",
                                    text: resp.message,
                                });
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        changeStatus(val) {
            let reasonGroup = "";
            if (val.id == 1) {
                this.data.DeliveryOrderLine.forEach((dol) => {
                    dol.ProofOfDeliveryLine.ReturnQuantity = 0;
                    dol.ProofOfDeliveryLine.AdjustedQuantity = dol.ProofOfDeliveryLine.Quantity;
                });
            } else if (val.id == 2) {
                reasonGroup = "POD Partial";
            } else if (val.id == 4) {
                reasonGroup = "POD Failed";
                this.data.DeliveryOrderLine.forEach((dol) => {
                    dol.ProofOfDeliveryLine.ReturnQuantity = dol.ProofOfDeliveryLine.Quantity;
                    dol.ProofOfDeliveryLine.AdjustedQuantity = 0;
                });
            }

            this.getReason(reasonGroup);
            this.logSelect(val);
        },
    },
    mounted() {
        this.getData();
    },
    watch: {
        checkedAll(val) {
            if (val) {
                this.selectedRouteAssignmentToAssign = [];
                this.table.data.forEach((el, index) => {
                    this.checked[index] = true;
                    this.selectedRouteAssignmentToAssign.push(el);
                });
            } else {
                if (this.selectedRouteAssignmentToAssign.length == this.checked.length) {
                    this.checked.forEach((el, index) => {
                        this.checked[index] = false;
                    });
                    this.selectedRouteAssignmentToAssign = [];
                }
            }
        },
    },
};
</script>
